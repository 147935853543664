import Vue from 'vue'
import VueRouter from 'vue-router'
import _accessApi from "@/router/_accessApi.js";
import axios from 'axios';

const Registration            = () => import('@/views/self_registration/registration/Registration.vue');
const RegisterForm            = () => import('@/views/self_registration/RegisterForm.vue');
const NewRegisterAddress      = () => import('@/views/self_registration/registration/NewRegisterAddress.vue');
const Page404                 = () => import('@/views/information_page/Error404.vue');
const SuccesPage              = () => import('@/views/information_page/SuccesPage.vue');
const ExpiredPage             = () => import('@/views/information_page/ExpiredPage.vue');
const ResponseEmailPage       = () => import('@/views/information_page/ResponseEmailPage.vue');
const ResponseDataCreatedPage = () => import('@/views/information_page/ResponseDataCreatedPage.vue');
const Verifying               = () => import('@/views/verifying/Verifying.vue');
const NewAddressSuccesPage    = () => import('@/views/information_page/NewAddressSuccesPage.vue');


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: configRoutes()
});


function configRoutes() {
  return [
    { path: "*", redirect: 'error-404' },
    {
      path: '/',
      redirect: { name: 'registration' }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Page404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/succes',
      name: 'succes',
      component: SuccesPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/expired/:urlcodeparam',
      name: 'expired',
      component: ExpiredPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/response-email',
      name: 'response-email',
      component: ResponseEmailPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/response-data-created',
      name: 'response-data-created',
      component: ResponseDataCreatedPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: "/registration",
      name: "registration",
      meta: {
        layout: 'full',
      },
      component: Registration
    },
    {
      path: "/registerform/:urlcodeparam",
      name: "registerform",
      meta: {
        layout: 'full',
      },
      component: RegisterForm
    },
    {
      path: "/new-register-address",
      name: "new-register-address",
      meta: {
        layout: 'full',
      },
      component: NewRegisterAddress
    },
    {
      path: "/verifying/:urlcodeparam",
      name: "verifying",
      meta: {
        layout: 'full',
      },
      component: Verifying
    },
    {
      path: '/new-addres-succes',
      name: 'new-addres-succes',
      component: NewAddressSuccesPage,
      meta: {
        layout: 'full',
      },
    },
  ];
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

if (location.host === "localhost:8080" || location.host === "localhost:8081") {
  //My localhost
  axios.defaults.baseURL = _accessApi.localhost.url;
  axios.defaults.upload_path = _accessApi.localhost.upload_path;
  axios.defaults.upload_path_preview = _accessApi.localhost.upload_path_preview;
  axios.defaults.upload_path_temp = _accessApi.localhost.upload_path_temp;
  axios.defaults.download_assets_preview = _accessApi.localhost.download_assets_preview;
  axios.defaults.download_assets_url = _accessApi.localhost.download_assets_url;
} else {
  //server
  axios.defaults.baseURL = _accessApi.server.url;
  axios.defaults.upload_path = _accessApi.server.upload_path;
  axios.defaults.upload_path_temp = _accessApi.server.upload_path_temp;
  axios.defaults.download_assets_preview = _accessApi.server.download_assets_preview;
  axios.defaults.download_assets_url = _accessApi.server.download_assets_url;
  axios.defaults.upload_path_preview = _accessApi.localhost.upload_path_preview;
}


router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('token');
  const publicPages = ['registration','new-register-address', 'error-404', 'succes', 'expired', 'response-email','response-data-created', 'verifying','new-addres-succes'];
  const extract = to.path.split('/');
  const authRequired = !publicPages.includes(extract[1]);


  if (authRequired && token === null) {
    next('/registration')
  } else if (publicPages.includes(extract[1]) === true ) {
    next()
  } else {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    // console.log(to)
    axios.post('/authentication/restricted', { path: to.path }).then((response) => {
      next();
    }).catch(error => {
       var temp = to.path.split('/');
      // console.log(to.path);
      // console.log(temp);
      alert('Your session has been expired, you session will be restart for 3 hours please complete your data.')
      next('/expired/'+temp[2])
    });
  }
});


router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
