export default {
    localhost: {
        url: 'http://localhost/netciti-api/public/',
        upload_path: 'http://localhost/netciti-api/upload/',
        upload_path_preview: 'https://s3-jkt1.dcloud.co.id/efab-temporary/',
        //upload_path: 'https://s3-jkt1.dcloud.co.id/efab/',
        upload_path_temp: 'http://localhost/netciti-api/upload_temp/',
        download_assets_preview: 'http://localhost/netciti-api/public/download/download-preview',
        download_assets_url: 'http://localhost/netciti-api/public/download/download-assets'
    },
    server: {
        // url: 'http://103.19.108.183/netciti-api/public/',
        // upload_path: 'http://103.19.108.183/assets/upload/',
        // upload_path_temp: 'http://103.19.108.183/assets/upload_temp/',
        url: 'https://apiefab.netciti.co.id/netciti-api/public/',
        //upload_path: 'https://apiefab.netciti.co.id/assets/upload/',
        upload_path: 'https://s3-jkt1.dcloud.co.id/efab/',
        upload_path_preview: 'https://s3-jkt1.dcloud.co.id/efab-temporary/',
        download_assets_preview: 'https://apiefab.netciti.co.id/netciti-api/public/download/download-preview',        
        download_assets_url: 'https://apiefab.netciti.co.id/netciti-api/public/download/download-assets'        
    }

}