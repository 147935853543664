import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import '@axios'
import BootstrapVue from 'bootstrap-vue'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import { ModelSelect } from "vue-search-select";

// signature
import VueSignaturePad from 'vue-signature-pad';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUsers,faCalendar,faCheckCircle,faCakeCandles } from '@fortawesome/free-solid-svg-icons'
import { fas, fa } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, faCakeCandles) 

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueSignaturePad);
Vue.component('ModelSelect', ModelSelect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css')
require('@core/scss/vue/libs/vue-flatpicker.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
