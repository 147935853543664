import Vue from 'vue'
import _accessApi from '@/router/_accessApi.js'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: (location.host === 'localhost:8080' || location.host === 'localhost:8081') ? _accessApi.localhost.url : _accessApi.server.url
})

Vue.prototype.$http = axiosIns
//Vue.use(VueAxios, axiosIns)

export default axiosIns
